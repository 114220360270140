import taxary from "./assets/taxary.webp";
import workforce from "./assets/workforce.svg";
import webuild from "./assets/webuild.svg";
import heihei from "./assets/heihei.jpg";

export const content = [
  "CREATIVE SERVICES",
  "●",
  "CONSULTING",
  "●",
  "PRODUCTS",
  "●",
  "IT SOLUTIONS",
  "●",
  "PROJECTS",
  "●",
  "DEV-OPS",
];
export const tabs = [
  { label: "ABOUT US", ref: "#" },
  { label: "PRODUCTS", ref: "#" },
  { label: "CONTACT US", ref: "#" },
];
export const sliderdata = [
  {
    id: 1,
    image: taxary,
    title: "Taxary",
    description: "Complete Industry Product",
    link:"https://taxary.aaleepheonixsolutions.com/",
  },
  {
    id: 2,
    image: workforce,
    title: "Workforce Management",
    description: "Manage Workforce Administration",
    link: "https://workforce.aaleepheonixsolutions.com/"
  },
  {
    id: 3,
    image: heihei,
    title: "HeiHei Synthetic",
    description: "Continuous real-time monitoring",
    link: "",
  },
];
export const TEXT_MESSAGES = {
  contact: "CONTACT US",
  CONTACT: "Contact Us",
  FORMERR: "Please fill the details and try again",
  contactdesc: "Our expert will assist you with the best solutions for you",
  callback:"Request a call back",
  FOLLOWUS: "Follow Us",
  allproducts: "ALL PRODUCTS",
  about: "ABOUT US",
  products: "PRODUCTS",
  maintext: " Products Are Not Just",
  create: "Created",
  subtext: "; They Are Designed to Make An",
  impact: "Impact",
  terms:"TERMS AND CONDITIONS",
  privacy:"PRIVACY POLICY",
  a: "a",
  i: "i",
  u: "u",
  p: "p",
  deliver: "DELIVER ON TIME",
  ourproducts: "Our Products",
  ourproductsleft: [
    { label: "Work Force Management", link: "https://workforce.aaleepheonixsolutions.com/" },
    { label: "Taxary", link: "https://taxary.aaleepheonixsolutions.com/" }, 
    { label: "Heihei Synthetic", link: "" },
  ],
 ourproductsright: [
    { label: "Dance Studio Application (UGApp)", link: "" },
    { label: "Learning Management System", link: "" },
    { label: "Fitness Applications (Fitclub)", link: "" },
    { label: "Porter Application", link: "" },
    { label: "Food Delivery Application", link: "" },
    { label: "We Build Websites", link: "https://webuildwebsites.pheonixsolutions.com/" },
    { label: "Maui SMS", link: "" },
  ],
  agri: "Agribreeders",
  who: "Who We Are",
  whomain: `We specialize in providing Server Administration, Web Hosting,
                App Development, and Support Services at competitive and
                affordable prices. Get started with our services today! Share
                your requirements, and our experienced team will get in touch
                with you.`,
  more: "More Details..",
  aboutlink: "https://pheonixsolutions.com/privacy-policy#aboutus",
  get: "Get Demo Today!",
  join: "Trusted by 500+ Enterprise Clients for Exceptional Results!",
  sales: "SALES OR PRODUCTION TARGETS FOR A PRODUCT",
  abouttext: " We are a Digital Production Team.",
  aboutmain:
    " Aalee Pheonix Solutions is a leading design and software development company based in India. We assist startups and Fortune 500 companies in delivering exceptional experiences to their users",
};

export const links = [
  {
    href: "https://pheonixsolutions.com/privacy-policy#aboutus",
    label: "Legal",
  },
  {
    href: "/terms",
    label: "Terms & Conditions",
  },
  {
    href: "/privacy",
    label: "Privacy Policy",
  },
  {
    href: "https://pheonixsolutions.com/privacy-policy#aboutus",
    label: "Cookie Policy",
  },
];
export const baseurl = {
  DEV_URL: "https://dev-api.aaleepheonixsolutions.com/graphql",
  UAT_URL: "https://uat-api.aaleepheonixsolutions.com/graphql",
  PROD_URL:"https://api.aaleepheonixsolutions.com/graphql",
  LOCAL_URL: "http://localhost:4000/graphql",
  }

