import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ContactModal from "./ContactModal";
import "../App.css";
import { TEXT_MESSAGES, links } from "../const";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "../assets/twitter.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import data from "../data.json";
interface PheonixMenuProps {
  onClose: () => void;
}
const PheonixMenu: React.FC<PheonixMenuProps> = ({ onClose }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [productsMenuOpen, setProductsMenuOpen] = useState(true);
  const [follow, setfollow] = useState(data["Follow Us"]);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleContactModalOpen = () => {
    handleMenuClose();
    setContactModalOpen(true);
  };

  const handleContactModalClose = () => {
    setContactModalOpen(false);
  };

  const linkStyle = {
    color: "#FFFFFF",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      fontFamily: "Lexend",
    },
  };

  const iconComponents: Record<string, any> = {
    FacebookIcon: FacebookIcon,
    LinkedInIcon: LinkedInIcon,
    InstagramIcon: InstagramIcon,
  };

  const getIconComponent = (iconName: string) => {
    if (iconName === "TwitterIcon") {
      return (
        <img
          src={TwitterIcon}
          alt="Twitter"
          style={{
            width: 18,
            height: 18,
            marginRight: "5px",
            marginTop: "2px",
          }}
        />
      );
    }
    const IconComponent = iconComponents[iconName];
    return IconComponent ? (
      <IconComponent sx={{ marginRight: { xs: 0, sm: "10px" } }} />
    ) : null;
  };

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: { lg: "500px" },
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(42, 41, 41, 0.97)",
        color: "rgba(159, 159, 159, 1)",
        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
        zIndex: 1200,
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: "5%",
          right: "2%",
          height: "24px",
          width: "24px",
          color: "#FFFFFF",
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: { lg: "46px", xs: "16px" },
            fontFamily: "Poppins",
          }}
        >
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              color: "white",
              marginBottom: "22px",
            }}
          >
            {TEXT_MESSAGES.ourproducts}
          </Box>
            <Grid item xs={12} sm={6} md={8}>
              {TEXT_MESSAGES.ourproductsleft.map((product, index) => (
                <Box
                  key={index}
                  sx={{
                    color: "rgba(159, 159, 159, 1)",
                    fontWeight: 500,
                    marginBottom: "20px",
                    cursor: product.link ? "pointer" : "default",
                    "&:hover": {
                      textDecoration: product.link ? "underline" : "none",
                      color: product.link
                        ? "#E63125"
                        : "rgba(159, 159, 159, 1)",
                    },
                  }}
                  onClick={() => {
                    if (product.link) {
                      window.open(product.link, "_blank");
                    }
                  }}
                >
                  {product.label}
                </Box>
              ))}
            </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            fontFamily: "Poppins",
            padding: { lg: "46px", xs: "16px" },
          }}
        >
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              color: "white",
              marginBottom: "16px",
            }}
          >
            {TEXT_MESSAGES.who}
          </Box>
          <Box
            sx={{
              lineHeight: "2",
              fontSize: { xs: "12px", sm: "14px" },
              fontWeight: 400,
              color: "rgba(159, 159, 159, 1)",
            }}
          >
            {TEXT_MESSAGES.whomain}
          </Box>
          <Box
            sx={{
              lineHeight: "2",
              fontSize: "14px",
              fontWeight: 400,
              color: "rgba(159, 159, 159, 1)",
              cursor: "pointer",
            }}
          >
            <a
              href={TEXT_MESSAGES.aboutlink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {TEXT_MESSAGES.more}
            </a>
          </Box>
          <Box
            sx={{
              marginTop: "28px",
              fontWeight: "bold",
              color: "white",
              marginBottom: "10px",
            }}
          >
            {TEXT_MESSAGES.get}
          </Box>
          <Box sx={{ color: "white" }}>{TEXT_MESSAGES.join}</Box>
          <Box
            sx={{
              marginTop: "28px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              className="hover-button"
              variant="contained"
              style={{
                textTransform: "none",
                whiteSpace: "nowrap",
                backgroundColor: "#212121",
                color: "rgba(219, 219, 219, 1)",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "12px",
              }}
              onClick={handleContactModalOpen}
            >
              {TEXT_MESSAGES.CONTACT}
            </Button>
            <Grid display="flex" flexDirection="row">
              {" "}
              {TEXT_MESSAGES.FOLLOWUS}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "10px",
                }}
              >
                {follow.map((item, idx) => (
                  <div
                    key={idx}
                    style={{
                      cursor: "pointer",
                      color: "#FFFFFF",
                    }}
                  >
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <Box
                        className="hover-button"
                        sx={linkStyle}
                        display="flex"
                        alignItems="center"
                      >
                        {"icon" in item && getIconComponent(item.icon)}
                      </Box>
                    </a>
                  </div>
                ))}
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "28px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: { xs: "8px", sm: "12px" },
            }}
          >
            {links.map((link, index) => (
              <Typography
                key={index}
                className="hover-link"
                sx={{
                  fontSize: { xs: "8px", sm: "12px" },
                }}
              >
                {link.href.startsWith("/") ? (
                  <Link
                    to={link.href}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                    onMouseEnter={(e) =>
                      ((e.target as HTMLAnchorElement).style.textDecoration =
                        "underline")
                    }
                    onMouseLeave={(e) =>
                      ((e.target as HTMLAnchorElement).style.textDecoration =
                        "none")
                    }
                  >
                    {link.label}
                  </Link>
                ) : (
                  <a
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                    onMouseEnter={(e) =>
                      ((e.target as HTMLAnchorElement).style.textDecoration =
                        "underline")
                    }
                    onMouseLeave={(e) =>
                      ((e.target as HTMLAnchorElement).style.textDecoration =
                        "none")
                    }
                  >
                    {link.label}
                  </a>
                )}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
      <ContactModal open={contactModalOpen} onClose={handleContactModalClose} />
    </Box>
  );
};
export default PheonixMenu;
